import { responseHandler, responseHelper } from '@asolu/Domain/request'
import { notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification'
import { AxiosResponse } from 'axios'
import { dispatch } from 'index'

export interface processedResponseData
{ data:any
  stateChange: 'CREATED' | 'UPDATED' | 'DELETED' | 'FETCHED' | 'INITIALIZED' | 'LOADED' | 'FAIL'
}

export interface notifications{showFail?:boolean, showSucess?:boolean}


export const processResponse = async (generator: unknown, response: AxiosResponse, overrideMessagePrefix?:string, overrideNotifications:notifications={showFail:true, showSucess:true}) => {
  try {
    const { status, data } = response
    const handler: responseHandler = responseHelper[status!]
    const messagePrefix= overrideMessagePrefix !=undefined ? overrideMessagePrefix: handler.messagePrefix
    switch (handler.status) {
      case 'Success':   
        if (handler.stateChange !== 'DELETE') {
         if(generator !== undefined)
         { 
          dispatch({
            type: generator,
            data,
            isDirty: false,
            stateChange: handler.stateChange,
          })
        }
        }   
        if(overrideNotifications.showSucess)  
        { postNotification(handler.messageHeader, messagePrefix, 'topLeft', 'Success') }
        return true
      case 'Fail':
        if(overrideNotifications.showFail)  
        {
        postNotification(handler.messageHeader, messagePrefix, 'topLeft', 'Fail')
        }
        return false
      default:
        return false
    }
  } catch (error) {
    postNotification("System Error", `details:${error}`, 'topLeft', 'Fail')
        return false
  }

  
}

export const postNotification = (
  message: string,
  description: string,
  placement: NotificationPlacement,
  type: 'Success' | 'Fail',
) => {
  switch (type) {
    case 'Success':
      notification.success({
        message,
        description,
        placement,
      })
      break
    case 'Fail':
      notification.error({
        message,
        description,
        placement,
      })
      break

    default:
      break
  }
}
