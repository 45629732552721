import { Modal } from 'antd'
import { dispatch } from 'index'
import { createPromiseAction } from 'lib/reduxSagaPromise'
import { initialState, menuWithSection, restaurantMenuState, sectionOrder } from './model'
import { Actions as Action, RestaurantMenuEffects } from './types'

// Attach Sections to Menu
export const attachSectionsToMenu = createPromiseAction(
  'ATTACH_SECTIONSTOMENU_REQUEST',
  'ATTACH_SECTIONSTOMENU_SUCCESS',
  'ATTACH_SECTIONSTOMENU_FAILURE',
)<menuWithSection[], unknown, unknown>()

export const attachSectionToMenu = createPromiseAction(
  'ATTACH_SECTIONTOMENU_REQUEST',
  'ATTACH_SECTIONTOMENU_SUCCESS',
  'ATTACH_SECTIONTOMENU_FAILURE',
)<menuWithSection[], unknown, unknown>()

export const deleteMenuSection = createPromiseAction(
  'DELETE_SECTIONSTOMENU_REQUEST',
  'DELETE_SECTIONSTOMENU_SUCCESS',
  'DELETE_SECTIONSTOMENU_FAILURE',
)<number, unknown, unknown>()

// fetchMenuSections
export const fetchMenuSections = createPromiseAction(
  'FETCH_MENU_SECTIONS_REQUEST',
  'FETCH_MENU_SECTIONS_SUCCESS',
  'FETCH_MENU_SECTIONS_FAILURE',
)<number, unknown, unknown>()
//
// Load initial state
export const resetInitialMenuState = (payload?: restaurantMenuState) => {
  const payloadData = payload !== undefined ? payload : initialState
  dispatch({
    type: Action.MENU_INITIALSTATE_RESET,
    data: payloadData,
  })
}
export const isKeyValid =(key:number, arrayLength:number | undefined) =>{
  return key !==0 && arrayLength !==0     
}

export const setTenantandLocation = (payload: any) => {
  dispatch({
    type: Action.MENU_TENANTLOCATION_SET,
    data: payload,
  })
}
export const getSections = (payload: number) => {
  dispatch({
    type: Action.SECTIONS_LOADED,
    data: [],
  })
}

export const updateSectionsState = async (payload: any) => {
  await dispatch({
    type: RestaurantMenuEffects.SET_SECTION_STATE,
    payload,
  })
}

export const updateSections = (payload: any) => {
  dispatch({
    type: Action.SECTION_DATA_CHANGED,
    data: payload,
  })
}

export const setDataIsDirty = (payload: any) => { 
  dispatch({
    type: RestaurantMenuEffects.SET_DATA_IS_DIRTY,
    data: payload,
  })
}

export const changeSectionOrder = (payload: sectionOrder) => {
  dispatch({
    type: Action.SECTION_ORDER_CHANGED,
    data: payload,
  })
}
export const createMenu = async (payload: any) => {
  await dispatch({
    type: Action.MENU_CREATED,
    data: payload,
  })
}



export const deActivateMenu = (payload: any) => {
  dispatch({
    type: Action.MENU_DEACTIVATED,
    data: payload,
  })
}

export const editMenu = (payload: any) => {
  dispatch({
    type: Action.MENU_EDITED,
    data: payload,
  })
}

export const saveMenu = async (id: number, payload: any) => {
  dispatch({
    type: Action.MENU_SAVED,
    id,
    data: payload,
  })
}

export const fetchMenu = (payload: any) => {
  dispatch({
    type: Action.FETCH_MENU_STARTED,
    data: payload,
  })
}

export const UpdateMenuSections = async (data:any)=>{
  const localModal = Modal.info({
    title: 'Please wait updating database with menu sections changes!',
    centered: true,
    keyboard: false,
    autoFocusButton: null,
    okButtonProps: { disabled: true, style: { display: 'none' } },
  })

 var cleanedData = data.map((item: any) => {
   return {...item, section:null}
 })
  await TriggerAction(Action.ATTACH_SECTIONS_TO_MENU, cleanedData).then(ret => {
  localModal.destroy()
 return ret      
 })

}

export const CreateSection = (parentId:number, length:number) => {
  const newSection = {
    isActive: true,
    isPrivate: true,
    menuId: parentId,
    sectionId: null,
    indexId: length! + 1,
  }
  // const nonMutArray = [...sections, newSection]
  TriggerAction(Action.ATTACH_SECTION_TO_MENU,newSection ).then((ret) => {
  
  })
}

export const TriggerAction = async (action: Action, payload: any) => {
  let ret
  switch (action) {
    case Action.DELETE_MENU_SECTION: {
      return await dispatch(deleteMenuSection.request(payload))
    }
    case Action.FETCH_MENU_SECTIONS: {
      await dispatch(fetchMenuSections.request(payload))
      break
    }
    case Action.ATTACH_SECTIONS_TO_MENU: {
      return await dispatch(attachSectionsToMenu.request(payload))
    }
    case Action.ATTACH_SECTION_TO_MENU: {
      return await dispatch(attachSectionToMenu.request(payload))
    }
    default:
      break
  }
  return ret
}
