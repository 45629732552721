import { AppState } from '../../../Domain/ver2storeTypes'

export interface sectionOrder {
  from: number | string
  to: number | string
  fromIndex: number
  toIndex: number
}

export type section = {
  id: number
  isActive: boolean
  isPrivate: boolean
  name: string
  description: string
  sectionId?: number
  indexId?: number
  xmin?:number
}

export type choiceSection = {
  id: number
  name: string
  typeId: number
  index: number
  parentId: number
  isActive:boolean
}

export type menuWithSection = {
  id: number
  xmin?: number
  isActive: boolean
  isPrivate: boolean
  section: section[] | null
  menuId: number
  sectionId: number
  indexId: number
}

export type restaurantMenu = {
  id: number
  name: string
  description: string
  totalSections: number
  sections: menuWithSection[]
  xmin: number
  locationId: number
  tenantId: number
}

export type restaurantMenuState = {
  current: restaurantMenu
  tempSections: choiceSection[]
  isDirty: boolean
  isDeleting: boolean
  isDeleted: boolean
  isCreating: boolean
  isCreated: boolean
  isSaving: boolean
  isSaved: boolean
  isFetched: boolean
  isFetching: boolean
  isLoading: boolean
  isLoaded: boolean
  dataWasTouched: boolean
  stateChange: 'CREATED' | 'CREATE' | 'UPDATE' | 'DELETE' | 'FETCHED' | 'INITIAL'
}

export type tenantLocation = {
  tenantId: number
  locationId: number
}

export const initialState: restaurantMenuState = {
  current: {
    id: -1,
    name: 'Add Name',
    totalSections: 0,
    sections: [],
    xmin: 0,
    locationId: 0,
    tenantId: 0,
    description: '',
  },
  tempSections: [],
  isDirty: false,
  isDeleting: false,
  isDeleted: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isFetched: false,
  isFetching: false,
  isLoading: false,
  isLoaded: false,
  dataWasTouched: false,
  stateChange: 'INITIAL',
}

export const sliceOfMenuState = (itemState: AppState) => itemState.restaurantMenuReducer

