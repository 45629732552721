import { AppState } from 'pages/AllergenSolu/Domain/ver2storeTypes'
import { restaurantMenu } from '../../../context/model'

export type tenant = {
  tenantId: number
  name: string
  description: string
  xmin: number
}

export type location = {
  locationId: number
  tenantId: number
  name: string
  description: string
  xmin: number
  menus: [restaurantMenu]
}

export type tenantProfile = {
  tenant: tenant | undefined
  menuCount: number | 0
  locationCount: number | 0
  locations: [location] | undefined | []
}

export type tenantState = {
  current: tenantProfile
  isDirty: boolean
  isLoading: boolean
  isLoaded: boolean
  childStateChanged: boolean
}

export const initialState: tenantState = {
  current: {
    tenant: undefined,
    locations: undefined,
    menuCount: 0,
    locationCount: 0,
  },
  isDirty: false,
  isLoading: false,
  isLoaded: false,
  childStateChanged: false,
}
export const sliceOfTenantState = (itemState: AppState) => itemState.tenantReducer
