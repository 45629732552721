export type pageTypes = 'PATRONVIEW' | 'DASHBOARD' | 'MENUSECTIONS' | 'MENUSECTIONADMIN' | undefined


export type menuWithSection = {
  isActive: boolean
  isPrivate: boolean
  menuId: number
  sectionId: number |null
  indexId: number
  xmin?: number
}

//  Event Triggers
export enum Actions {
  ATTACH_SECTIONS_TO_MENU = 'ATTACHSECTIONSTOMENU',
  ATTACH_SECTION_TO_MENU = 'ATTACHSECTIONTOMENU',
  FETCH_MENU_SECTIONS = 'FETCHMENUSECTIONS',
  DELETE_MENU_SECTION = 'DELETEMENUSECTION',
  MENU_INITIALSTATE_RESET = 'MENUINITIALSTATERESET',
  MENU_TENANTLOCATION_SET = 'MENUTENANTLOCATIONSET',
  MENU_TENANT_SET = 'MENUTENANTSET',
  FETCH_MENU_STARTED = 'FETCHMENUSTARTED',
  FETCH_MENU_RECEIVED = 'FETCHMENURECEIVED',
  FETCH_MENU_FAILED = 'FETCHMENUFAILED',
  MENU_DEACTIVATED = 'MENUDEACTIVATED',
  MENU_CREATED = 'MENUCREATED',
  MENU_CREATED_RESPONSE_PROCESSED= 'MENUCREATEDRESPONSEPROCESSED',
  MENU_SAVED = 'MENUSAVED',
  MENU_EDITED = 'MENUEDITED',
  RESPONSE_PROCESSED = 'RESPONSEPROCESSED',
  SECTION_ORDER_CHANGED = 'SECTIONORDERCHANGED',
  SECTION_DATA_CHANGED = 'SECTIONDATACHANGED',
  SECTIONS_LOADED = 'SECTIONSLOADED',
  MENUSECTIONS_DIRTY_DATA_CHANGED = 'MENUSECTIONSDIRTYDATACHANGED',
}

// TriggerActions from
export enum RestaurantMenuEffects {
  RESET_INITIALSTATE = 'RESET_INITIALSTATE',
  SET_TENANTLOCATION = 'SET_TENANTLOCATION',
  SET_LOCATION = 'SET_LOCATION',
  SET_STATE = 'SET_STATE',
  SET_SAVED_DATATOSTATE = 'SET__SAVED_DATATOSTATE',
  SET_MENUSECTIONS_DIRTY_DATA = 'SET_MENUSECTIONS_DIRTY_DATA',
  SET_SECTION_TEMP_STATE = 'SET_SECTION_TEMP_STATE',
  SET_SECTION_STATE = 'SET_SECTION_STATE',
  SECTIONS_LOADED = 'SECTIONS_LOADED',
  SECTIONS_EDITED = 'SECTIONS_EDITED',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_DATA_FETCHED = 'SET_DATA_FETCHED',
  SET_DATA_FETCH_FAILED = 'SET_DATA_FETCH_FAIL',
  SET_DATA_IS_DIRTY = 'SET_DATA_IS_DIRTY',
  SET_DEACTIVATED_STATE = 'SET_DEACTIVATED_STATE',
}
