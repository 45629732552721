import { all, takeEvery } from 'redux-saga/effects'
import { takeEveryPromiseAction } from 'lib/reduxSagaPromise/effects'
import {
  Generators,
  processResponse,
  PROCESSFETCHSECTIONS,
  processCreateSection,
  PROCESSECTIONSTATEUPDATE,
  PROCESSUPDATEMENUSECIONITEMS,
  PROCESSDELETEMENUSECTION,
  PROCESSUPDATESECTION, 
  PROCESSDELETESECTION,
  PROCESSPUTMENUSECTION,
  PROCESSFETCHSECTIONTYPES
} from './generators'
import * as actions from './actions'

export default function* rootSaga() {
  yield all([
    takeEveryPromiseAction(actions.deleteSection, PROCESSDELETESECTION),
    takeEveryPromiseAction(actions.updateSection, PROCESSUPDATESECTION),
    takeEveryPromiseAction(actions.createSection, processCreateSection),
    takeEveryPromiseAction(actions.deleteMenuSection, PROCESSDELETEMENUSECTION),
    takeEveryPromiseAction(actions.processResponse, processResponse),
    takeEveryPromiseAction(actions.updateMenuSectionItems, PROCESSUPDATEMENUSECIONITEMS),
    takeEveryPromiseAction(actions.updateSectionState, PROCESSECTIONSTATEUPDATE),
    takeEveryPromiseAction(actions.updateMenuSection, PROCESSPUTMENUSECTION),    
     takeEvery(Generators.PROCESS_FETCH_SECTIONS, PROCESSFETCHSECTIONS),
     takeEvery(Generators.PROCESS_FETCH_SECTIONTYPES, PROCESSFETCHSECTIONTYPES),
  ])
}
