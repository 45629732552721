import { call, put } from 'redux-saga/effects'
import { dispatch } from 'index'
import { processResponse } from 'lib/actionprocessor'
import {
  deleteMenu,
  getMenu,
  getMenuSections,
  postMenu,
  postSectiontoMenu,
  putMenu,
} from './services'
import { RestaurantMenuEffects, Actions, menuWithSection } from './types'
import * as ActionsTrigger from './triggeraction'
import { AxiosResponse } from 'axios'
import { restaurantMenu } from './model'

export function* PROCESSATTACHSECTIONS(
  payload: ReturnType<typeof ActionsTrigger.attachSectionsToMenu.request>,
) {
  const { payload: payloadData } = payload
  const ret: AxiosResponse = yield call(postSectiontoMenu, { data: payloadData })
  yield ret
  const retProcessResponse: boolean = yield call(processResponse, undefined, ret,"Section added to menu!")
  yield retProcessResponse
  return retProcessResponse
}

export function* PROCESSATTACHSECTION(
  payload: ReturnType<typeof ActionsTrigger.attachSectionToMenu.request>,
) {
  const { payload: payloadData } = payload
  yield payloadData
  return payloadData
}

export function* PROCESSDELETEMENUSECTION(
  payload: ReturnType<typeof ActionsTrigger.deleteMenuSection.request>,
) {
  yield payload
  // const ret:AxiosResponse= yield call(deleteSectionMenu, payload)
  // yield ret  
  return payload
}
export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

export function* PROCESSFETCHSECTIONS(
  payload: ReturnType<typeof ActionsTrigger.fetchMenuSections.request>,
) {
  const response: ResponseGenerator = yield call(getMenuSections, payload.payload as number)
  return response;
}

export function* MENUTENANTLOCATIONSET(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SET_TENANTLOCATION,
    data: payload.data,
  })
}

export function* SECTIONSLOADED(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SECTIONS_LOADED,
    data: payload.data,
    isDirty: false,
  })
}

export function* SECTIONDATACHANGED(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SET_SECTION_STATE,
    data: payload.data,
    isDirty: false,
    stateChange: "UPDATED"
  })
}

export function* MENUSECTIONSDIRTYDATACHANGED(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SET_MENUSECTIONS_DIRTY_DATA,
    data: payload.data,
    isDirty: false,
  })
}

// export function* SECTIONORDERCHANGED(payload: any) {
//   // console.log(payload.data)
//   return yield put({
//     type: RestaurantMenuEffects.SET_SECTION_STATE,
//     data: payload.data,
//     isDirty: true,
//     stateChange:"UPDATED"
//   })
// }

export function* MENUDEACTIVATED(payload: any) {
  const { id, name } = payload.data
  const response: AxiosResponse = yield call(deleteMenu, id)
  response.data = name
  if (processResponse(Actions.RESPONSE_PROCESSED, response)) {
    yield put({
      type: RestaurantMenuEffects.SET_DEACTIVATED_STATE,
    })
  }
}
export function* MENUEDITED(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SET_STATE,
    data: payload.data,
    isDirty: true,
  })
}
export function* MENUSAVED(payload: any) {

  const { data, id } = payload
  const { sections, ...rest } = data;
  const menuPutResp: AxiosResponse = yield call(putMenu, id, rest)
  yield menuPutResp
  const retProcessResponse: boolean = yield call(processResponse, undefined, menuPutResp)
  yield retProcessResponse
  yield put({
    type: RestaurantMenuEffects.SET_SAVED_DATATOSTATE,
    data: data,
    isDirty: false,
  })
  return retProcessResponse
}

export function* MENUCREATED(payload: any) {
  const response: AxiosResponse = yield call(postMenu, payload)
  const ret: boolean = yield processResponse(Actions.MENU_CREATED_RESPONSE_PROCESSED, response, "Menu Created")
  return ret
}

export function* MENUCREATEDRESPONSEPROCESSED(payload: any) {
  if (payload) {   
    const { data, stateChange } = payload
    const { totalSections, id } = data
    const totalSectionCount = totalSections > 0 ? totalSections : 1
   
    let menuWithSections: menuWithSection[] = []
    let i: number
    for (i = totalSectionCount; i >= 1; i--) {
      menuWithSections.push({
        isActive: true,
        isPrivate: true,
        menuId: id,
        sectionId: null,
        indexId: i,
      })
    }
    
    const retAttach = ActionsTrigger.TriggerAction(Actions.ATTACH_SECTIONS_TO_MENU, menuWithSections).then(ret => {
      return ret
    })
    yield retAttach

    const retState: restaurantMenu = yield put({
      type: RestaurantMenuEffects.SET_SAVED_DATATOSTATE,
      data: data,
      stateChange: stateChange,
      isDirty: false,
    })
    yield retState   
  }

}

export function* FETCHMENUSTARTED(payload: any) {
  // console.log("fetchingmenu")
  yield put({
    type: RestaurantMenuEffects.SET_IS_FETCHING,
    data: true,
  })
  const response: AxiosResponse = yield call(getMenu, payload.data)
  if (response.status === 200) {
    // console.log("trytodispatch")
    yield dispatch({
      type: Actions.FETCH_MENU_RECEIVED,
      data: response.data,
    })
  } else {
    yield dispatch({
      type: Actions.FETCH_MENU_FAILED,
      data: payload,
    })
  }
}

export function* FETCHMENURECEIVED(payload: any) {
  yield put({
    type: RestaurantMenuEffects.SET_STATE,
    data: payload.data,
    isDirty: false,
  })
  // console.log("fetching")
  yield put({
    type: RestaurantMenuEffects.SET_DATA_FETCHED,
    data: true,
  })
}

export function* RESPONSEPROCESSED(payload: any) {  
  yield put({
    type: RestaurantMenuEffects.SET_SAVED_DATATOSTATE,
    data: payload.data,
    stateChange: payload.stateChange,
  })
}

export function* MENUINITIALSTATERESET(payload: any) {
  yield put({
    type: RestaurantMenuEffects.RESET_INITIALSTATE,
    data: payload.data,
  })

  yield put({
    type: RestaurantMenuEffects.SET_DATA_FETCHED,
    data: true,
  })
}
