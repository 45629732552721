import { call, put } from 'redux-saga/effects'
import { tenantActions } from './actions'
import { getMenusByLocation, getTenant } from './service'
import { tenantProfile } from './model'

export enum TenantEffects {
  TENANT_STATE_LOAD = 'TENANTSTATELOAD',
  TENANT_INITIALSTATE_LOAD = 'TENANT_INITIALSTATE_LOAD',
}

// effects
export function* TENANTSTATELOAD(payload: any) {
  const { data } = payload
  yield put({
    type: tenantActions.SET_STATE_LOADING,
  })
  const response = yield call(getTenant, data)
  let tenantProfileObj: tenantProfile
  const { status, data: TenantObj } = response
  if (status === 200 || status === 201) {
  const locationId = ():number=>{
    switch (TenantObj.id) {
      case 1:
        return 1
      case 5:
        return 2     
      case 7:
        return 3
      case 9:
        return 4
      case 10:
        return 5
      case 11:
        return 6
      default:
        return 0
    }
  }
    const menuResponse = yield call(getMenusByLocation, locationId())
    const { data: menudata } = menuResponse
    const locationObj = {
      locationId: locationId(),
      tenantId: TenantObj.id,
      name: '',
      description: '',
      menus: menudata,
      xmin: 12222,
    }
    tenantProfileObj = {
      tenant: response.data,
      menuCount: menudata.length,
      locationCount: 1,
      locations: [locationObj],
    }
    yield put({
      type: tenantActions.SET_INITIAL_STATE,
      data: tenantProfileObj !== undefined ? tenantProfileObj : undefined,
    })
    yield put({
      type: tenantActions.SET_STATE_LOADED,
    })
  }
}
