import React from 'react'
import { Route, Redirect, history} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { dispatch } from 'index'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import path from 'path'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    // eslint-disable-next-line react/display-name
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/dashboard/analytics',
    Component: loadable(() => import('pages/dashboard/analytics')),
    exact: true,
  },
  // Feature Pages
  {
    path: '/admin/MenuProfile',
    Component: loadable(() => import('@asolu/features/RestaurantMenu')),
    exact: true,
  },
  {
    path: '/admin/patron',
    Component: loadable(() => import('@asolu/features/diner')),
    exact: true,
  },
  {
    path: '/patron',
    Component: loadable(() => import('@asolu/features/diner')),
    exact: true,
  },
  {
    path: '/mypatron',
    Component: loadable(() => import('@asolu/features/diner')),
    exact: true,
  },
  {
    path: '/features/MenuSafeItm',
    Component: loadable(() => import('@asolu/features/Items')),
    exact: true,
  },
  // Feature Pages
  {
    path: '/features/MenuSafeCat',
    Component: loadable(() => import('@asolu/features/Categories')),
    exact: true,
  },
  // Feature Pages
  {
    path: '/features/MenuSafeAllergens',
    Component: loadable(() => import('@asolu/features/Allergens')),
    exact: true,
  },
  //MenuSafeComponents
  {
    path: '/features/MenuSafeComponents',
    Component: loadable(() => import('@asolu/features/Ingredients')),
    exact: true,
  },
  //MenuSafeComponents
  // {
  //   path: '/features/Menu',
  //   Component: loadable(() => import('@asolu/features/Menu')),
  //   exact: true,
  // },
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/system/register')),
    exact: true,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {  
  render() {
    const subdomain = window.location.hostname.split('.')[0]
    const subdomainValid = subdomain !== undefined && subdomain !==null && subdomain.length > 0 ? true : false
    console.log(window.location)
    let  tenantId = 0 
    if(subdomainValid)
    {
      if(subdomain === 'loginn')
     {
       tenantId = 10
     }
       
    }   
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={        
              props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            {
             tenantId > 0 &&              
             <Route exact path="/patron"
             render={() => {
              const Comp = loadable(() => import('@asolu/features/diner'))
              return( 
                <Comp  myKey="10" />
              )
             }}
             />
            }
                     
          
            <Route exact path="/" render={() => <Redirect to="/dashboard/analytics" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
