import { ApiGateway } from '@asolu/Domain/request'
// import { section } from "./model"

export const deleteSectionMenu = async (payload: any) => {
  if (payload !== undefined) {
      return await ApiGateway.delete(`/api/MenuWithSectionsAsync/${payload}`)
  }
   return false
}

export const postSection = async (payload: any) => {
    if (payload !== undefined) {
    return await ApiGateway.post('/api/SectionAsync', payload)
  }
  return undefined
}

export const putSection = async (payload: any) => { 
 try {
  const {id} = payload
  
  //const modPayload = {...payload,isActive:false}
  if (payload !== undefined && id > 0) {
    let url = '/api/SectionAsync/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const content = JSON.stringify(payload)
    const response = await ApiGateway.put(url, content)  
    return response
  }  
  return undefined  
 } catch (error) {
   console.log(error)
   return undefined
 }
}

export const getSectionMenuItems = async (id: number) => {
  if (id !== undefined) {
    let url = '/api/SectionWithItemsAsync/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const response = await ApiGateway.get(url).then((ret) => {
      return ret.data
    })
    return response
  }
  return 'Fail'
}

export const putSectionMenuItems = async (payload: any) => {
  // console.log(payload)
  const { id, data } = payload

  if (payload !== undefined && id !== undefined) {
    let url = '/api/SectionWithItemsAsync/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const content = JSON.stringify(data)
    const response = await ApiGateway.put(url, content)
    return response
  }
  return undefined
}

export const deleteSection = (id:number) => {
  const response = ApiGateway.delete(`/api/SectionAsync/${id}`).then((i) => {
    return i
  })
  return response
}

export const getSectionTypes = () => {
  const response = ApiGateway.get('/api/SectionAsync').then((i) => {
    return i
  })
  return response
}

export const getSectionTypesByMenu = (menuId:number) => {
  const response = ApiGateway.get(`/api/SectionAsync/GetByMenuId/${menuId}`).then((i) => {
    return i
  })
  return response
}

export const getAvailableMenuItems = async (tenantId=0) => {
  const response = await ApiGateway.get(`/api/ItemAsync/GetAllWithIng/${tenantId}`) 
  return response
}

export const getSection = async () => {
  const response = await ApiGateway.get('/api/SectionAsync')
  // console.log(response)
  return response
}

export const putMenuSection = async (id: number, payload: any) => {
  // console.log(id,payload)
  if (payload !== undefined && id !== undefined) {
    let url = '/api/MenuWithSectionsAsync/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const content = JSON.stringify(payload)
    const response = await ApiGateway.put(url, content)
    return response
  }
  return undefined
}
