import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    locale: 'en-US',
    isSidebarOpen: true,
    isSettingsModuledEnabled: false,
    isSupportModuleEnabled: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    isMenuShadow: false,
    isMenuUnfixed: false,
    menuLayoutType: 'top', // left, top, top-dark, nomenu
    menuType: 'flyout', // default, flyout, compact
    menuColor: 'dark', // dark, blue, gray, white
    flyoutMenuColor: 'white', // dark, blue, gray, white
    systemLayoutColor: 'gray', // white, dark, blue, gray, image
    isTopbarFixed: false,
    isFooterDark: false,
    isContentNoMaxWidth: true,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isGrayTopbar: true,
    isCardShadow: false,
    isSquaredBorders: false,
    isBorderless: false,
    tenantId: 0,
    tenantName: '',
    showUser:true,
    app: {
      appName: 'Food Allergy Solutions',
      appType: 'Admin Tool',
      appHeroMin: 'Eliminating Allergen Barriers',
      appDetail: 'Eliminating Allergen Barriers with a complete set of unique applications.',
      sysAdmin: '',
      sysKey: '1234',
    },
    routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
