import firebase from 'firebase/app'
import { notification } from 'antd'
import actions, { dispatcher } from 'redux/user/actions'
import settings_actions from 'redux/settings/actions' 
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'


var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth

export default firebaseApp

export async function login(email, password) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then((auth) => {
      return true
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => 
    {   return true   } 
    )
}

export async function setAgreement(){
  dispatcher(actions.SET_AGREEMENT, { tenantId:0, privacyAgreed: false, eulaAgreed: false})
}

export async function setSession(key,value){
  dispatcher(actions.SET_SESSION, { key, value})
}

export async function setTenant(tenantId){  
  console.log(tenantId)
  dispatcher(settings_actions.CHANGE_SETTING, {setting: 'tenantId',  value: tenantId,})
}



