import { dispatch } from 'index'
export const dispatcher = (action, payload) => {
  dispatch({
    type: action,
    payload
  })

}

const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_SESSION: 'user/SET_SESSION',
  SET_AGREEMENT: 'user/SET_AGREEMENT',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  GUEST_LOGIN: 'user/LOGIN_GUEST'
}

export default actions
