import { AppState } from '@asolu/Domain/ver2storeTypes'
import { baseState } from '@asolu/features/Base/kisactions'
import { section } from '@asolu/features/RestaurantMenu/context/model'

export type sectionsState = baseState & {
  current: section
  availableSections: section[]
  availableSectionTypes: section[]
  indexId: number
}

export const sectionsInitialState: sectionsState = {
  current: { id: -1, isActive: false, isPrivate: false, name: 'Add New', description: '' },
  isDirty: false,
  dataWasTouched: false,
  stateChange: 'INITIALIZED',
  indexId: 0,
  availableSections: [],
  availableSectionTypes:[]
}

export const sliceofSectionsState = (itemState: AppState) => itemState.restaurantMenuSectionReducer
export const sliceOfCurrentSectionState = (itemState: AppState) =>
  itemState.restaurantMenuSectionReducer.current
