import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Status from './Status'
import LanguageSwitcher from './LanguageSwitcher'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'
const mapStateToProps = ({ menu, user, settings }) => ({ menu, user, settings })

@connect(mapStateToProps)
class TopBar extends React.Component {
  render() {
    const { menu, crumbPath, showActions, showStatus, showLanguage, user,settings: {showUser } }= this.props
    const extraBreadcrumbItems = () => {
      const url = crumbPath.pathname
      const retChildUrl = itm => {
        const top = itm[0].url === url
        if (!top) {
          let items = [
            <Link
              to="/"
              key={itm[0].url}
              className={`${style.breadcrumbLink} ${top ? style.breadcrumbLink__current : ''}`}
            >{`${itm[0].title}/`}</Link>,
          ]

          var retItm = itm.find(function (menuItm, index) {
            if (menuItm.url === url) return true
            if (menuItm.count > 0) {
              var retchild = menuItm.children.find(function (childItm, index) {
                if (childItm.url === url) return true
                else return false
              })
              return retchild
            }
            return false
          })
          if (retItm) {
            items.push(
              <Link
                key={retItm.url}
                href="#"
                to={retItm.url}
                className={`${style.breadcrumbLink} ${style.breadcrumbLink__current}`}
              >
                {retItm.title}
              </Link>,
            )
            return items
          }
        }
        return undefined
      }
      return <>{retChildUrl(menu.menuData)}</>
    }
    return (
      <div className={style.topbar}>
        <div className="mr-md-4 mr-auto" />
        <ul className={`${style.breadcrumbs} mr-4`}>
          <li className={`${style.breadcrumb}`}>{user.tenantName}:{extraBreadcrumbItems()}</li>
        </ul>
        <div className="mr-auto d-none d-md-block" />
        {showStatus &&
          <div className="mb-0 mr-4 d-xl-block d-none">
            <Status />
          </div>
        }
        {showLanguage &&
          <div className="mr-4 d-none d-sm-block">
            <LanguageSwitcher />
          </div>
        }
        {showActions &&
          <div className="mr-4 d-none d-sm-block">
            <Actions />
          </div>
        }
        {showUser &&
          <div className="">
            <UserMenu />
          </div>
        }
      </div>
    )
  }
}

export default TopBar