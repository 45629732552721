export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-bookmark',
      url: '/dashboard/analytics',
    },
    {
      title: 'Diner',
      key: 'diner',
      icon: 'fe fe-bookmark',
      url: '/admin/patron',
    },
    {
      title: 'Menu Admin',
      key: 'menuAdmin',
      icon: 'fe fe-home',
      count: 1,
      children: [
        {
          title: 'Menus',
          key: 'menuProfiles',
          icon: 'fe fe-settings',
          url: '/admin/MenuProfile',
        },
      ],
    },
    {
      title: 'Config Data',
      key: 'menuData',
      icon: 'fe fe-home',
      count: 4,
      children: [
        {
          title: 'Menu Categories',
          key: 'catadmin',
          icon: 'fe fe-bookmark',
          url: '/features/MenuSafeCat',
        },
        {
          title: 'Menu Allergens',
          key: 'allmenus',
          icon: 'fe fe-bookmark',
          url: '/features/MenuSafeAllergens',
        },
        {
          title: 'Menu Components',
          key: 'compadmin',
          icon: 'fe fe-bookmark',
          url: '/features/MenuSafeComponents',
        },
        {
          title: 'Menu Items',
          key: 'itmadmin',
          icon: 'fe fe-bookmark',
          url: '/features/MenuSafeItm',
        },
      ],
    },
  ]
}
