import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import AppLayout from './App'
import PatronLayout from './Patron'
import path from 'path'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  app: AppLayout,
  patron: PatronLayout
}

const mapStateToProps = ({ settings, user }) => ({
  title: settings.app.title,
  user,
})

@withRouter
@connect(mapStateToProps)
class Layout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props
    const {
      location: { pathname: prevPathname },
    } = prevProps
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      title,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
       if (pathname === '/') {
        return 'public'
      } 
      if(pathname == '/admin/patron' || pathname == '/patron' || pathname == '/admin/patron/' || pathname == '/patron/')
        { return 'patron' }
      if (/^\/mypatron(?=\/|$)/i.test(pathname)) {
        return 'patron'
      }
      if(pathname == '/admin/patron' || pathname == '/patron')
         return 'patron'
      if (/^\/mypatron(?=\/|$)/i.test(pathname)) {
        return 'patron'
      }
      if (/^\/patron(?=\/|$)/i.test(pathname)) {
        return 'patron'
      }
       if (/^\/system(?=\/|$)/i.test(pathname)) {
        return 'auth'
      }
      return 'app'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isAuthLayout = getLayout() === 'auth'
    const isPublicLayout = getLayout() === 'patron'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
        return <Loader />
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isAuthLayout && !isPublicLayout && !isUserAuthorized) {
          return <Redirect to="/system/login" />
      }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <>
        <Helmet titleTemplate="" title={title} />
        {BootstrappedLayout()}
      </>
    )
  }
}

export default Layout
