import produce from 'immer'
import { RestaurantMenuEffects } from './types'
import { restaurantMenuState, initialState } from './model'

export const restaurantMenuReducer = produce(
  (state: restaurantMenuState = initialState, action: any) => {
    const { type, payload } = action     
     switch (type) {
      case 'UPDATE_MENU_SECTION_SUCCESS': {
        const id = state.current.sections.findIndex(elm => elm.indexId === payload.indexId)
        const section = state.current.sections[id]
        state.current.sections[id] = { ...section, ...payload }
        state.current.sections[id].section = null
        state.isDirty = true
        return state
      }
      case 'DELETE_MENU_SECTION_SUCCESS':
        {
          const { data } = payload        
          if (data > -1) {
            state.current.sections.findIndex(section =>
              section.id === data)
            state.current.sections[data].isActive = false
            state.current.sections[data].indexId = -1
            const itemToMoveToEndofArray = state.current.sections.splice(data, 1)
            const updatedSectionState = state.current.sections.map((item, index) => {
              item.indexId = index + 1
              return item
            })    
            updatedSectionState.push(itemToMoveToEndofArray[0])         
            state.current.sections = updatedSectionState
            state.isDirty = true
          }        
          return state
        }
      case 'FETCH_MENU_SECTIONS_SUCCESS':
        try {
          state.current.sections = payload.data
        } catch (error) {
          console.log(error)
        }
        break
      case RestaurantMenuEffects.RESET_INITIALSTATE:
        state = action.data
        break
      case RestaurantMenuEffects.SET_DEACTIVATED_STATE:
        state.dataWasTouched = true
        break
      case RestaurantMenuEffects.SET_SAVED_DATATOSTATE:
        state.current = action.data
        state.current.totalSections =
          action.data.sections !== null && action.data.sections.length > 0 ? action.data.sections.length : action.data.totalSections
        state.isDirty = action.isDirty
        state.dataWasTouched = true
        state.stateChange = action.stateChange
        return state
      case RestaurantMenuEffects.SET_STATE:
        state.current = action.data
        state.current.totalSections =
          action.data.sections.length > 0 ? action.data.sections.length : action.data.totalSections
        state.stateChange = 'FETCHED'
        state.isDirty = action.isDirty
        break
      case RestaurantMenuEffects.SET_SECTION_STATE: {
        const { data, isDirty, stateChange } = action.payload
        state.current.sections = data
        state.isDirty = isDirty
        state.stateChange = stateChange
        break
      }
      case RestaurantMenuEffects.SET_TENANTLOCATION:{
        const {tenantId,locationId}=action.data              
        console.log(action.data)
        state.current.tenantId = tenantId
        state.current.locationId = locationId
        return state
      }
      case RestaurantMenuEffects.SET_IS_FETCHING:
        state.isFetching = action.data
        break
      case RestaurantMenuEffects.SET_DATA_FETCHED:
        state.isFetched = action.data
        state.isFetching = !action.data
        state.isDirty = false
        state.dataWasTouched = false
        state.stateChange = 'FETCHED'
        break
      case RestaurantMenuEffects.SET_DATA_IS_DIRTY:
        state.isDirty = action.data
        break
      case "ATTACH_SECTIONTOMENU_SUCCESS": {
        const { payload } = action
        state.isDirty = true
        state.current.sections.push(payload)
        return state
      }
      case "ATTACH_SECTIONSTOMENU_SUCCESS": {
        state.isDirty = false
        return state
      }

      //   default:
      //     neverReached()
    }
    return state
  },
)
