import { all } from 'redux-saga/effects'
import sections from '@asolu/features/RestaurantMenu/components/menuSections/context/sagas'
import restaurantMenus from '@asolu/features/RestaurantMenu/context/sagas'
import tenant from '@asolu/features/RestaurantMenu/components/dashboard/context/actions'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), restaurantMenus(), tenant(), sections()])
}
