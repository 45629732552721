import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { restaurantMenuSectionReducer } from '@asolu/features/RestaurantMenu/components/menuSections/context/reducer'
import { restaurantMenuReducer } from '@asolu/features/RestaurantMenu/context/reducers'
import { tenantReducer } from '@asolu/features/RestaurantMenu/components/dashboard/context/actions'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    restaurantMenuReducer,
    tenantReducer,
    restaurantMenuSectionReducer,
  })
