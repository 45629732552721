import { ApiGateway } from 'pages/AllergenSolu/Domain/request'
import { restaurantMenu } from './model'

export const deleteMenu = async (payload: any) => {
  if (payload !== undefined || payload === null) { 
    let menuWithSectionsUrl = '/api/MenuWithSectionsAsync/DeleteByMenuId/{data}'
    menuWithSectionsUrl = menuWithSectionsUrl.replace('{data}', encodeURIComponent(`${payload}`))
    menuWithSectionsUrl = menuWithSectionsUrl.replace(/[?&]$/, '')    
    return await ApiGateway.delete(menuWithSectionsUrl).then(async (resp) => {
         let url = '/api/MenuAsync/{data}'
      if (payload === undefined || payload === null)
        throw new Error("The parameter 'id' must be defined.")
      url = url.replace('{data}', encodeURIComponent(`${payload}`))
      url = url.replace(/[?&]$/, '')
      return await ApiGateway.delete(url).then(resp => {
        return resp
      })
    })    
  }
  else {
    return undefined
  }
}

// Refactor move to own model component
export const getMenuSections = async (id: number) => {
  if (id !== undefined) {
    let url = '/api/MenuWithSectionsAsync/GetByMenu/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const ret = await ApiGateway.get(url)
    return ret
  }
  return 'Fail'
}

export const deleteSectionMenu = async (payload: any) => {
  const { data } = payload
  if (data !== undefined) {
    const response = await ApiGateway.delete('/api/MenuWithSectionsAsync', { data })
    return response
  }
  return 'Fail'
}

export const postSectiontoMenu = async (payload: any) => {
  const { data } = payload
  if (data !== undefined) {
    // let url = '/api/SectionWithItemsAsync'
    //     if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    //     url = url.replace('{id}', encodeURIComponent(`${id}`))
    //     url = url.replace(/[?&]$/, '')
    //  const content = JSON.stringify(data)
    //  const response = await ApiGateway.post(url, content)
    //  return response
    return await ApiGateway.post('/api/MenuWithSectionsAsync', data)
  }
  return undefined
}

export const postMenu = async (payload: any) => {
  const { data } = payload
  const { name, sections, totalSections, tenantId, locationId } = data as restaurantMenu
  if (data !== undefined) {
    const response = await ApiGateway.post('/api/MenuAsync', {
      name,
      sections,
      locationId: locationId,
      tenantId: tenantId,
      totalSections,
    })
    return response
  }
  return undefined
}

export const putMenu = async (id: number, data: any) => {
  if (data !== undefined && id !== undefined) {
    let url = '/api/MenuAsync/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const content = JSON.stringify(data)
    const response = await ApiGateway.put(url, content)
    return response
  }
  return undefined
}

export const getMenu = async (id: number) => {
  if (id !== undefined) {
    let url = '/api/MenuAsync/GetById/{id}'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url = url.replace('{id}', encodeURIComponent(`${id}`))
    url = url.replace(/[?&]$/, '')
    const response = await ApiGateway.get(url)
    return response
  }
  return undefined
}
