import produce from 'immer'
import { all, takeLatest } from 'redux-saga/effects'
import { initialState, tenantState } from './model'
import { TENANTSTATELOAD } from './effects'

// Saga Effect Exports to Middleware
export default function* rootSaga() {
  yield all([takeLatest('TENANTSTATELOAD', TENANTSTATELOAD)])
}

// State Actions from
export enum tenantActions {
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_STATE = 'SET_STATE',
  SET_STATE_LOADING = 'SET_STATE_LOADING',
  SET_STATE_LOADED = 'SET_STATE_LOADED',
}
const actions = tenantActions

// eslint-disable-next-line @typescript-eslint/no-empty-function
const neverReached = () => {}

// Reducers
// Blog: Add immer project npm install immer
export const tenantReducer = produce((state: tenantState = initialState, action: any) => {
  switch (action.type) {
    case actions.SET_INITIAL_STATE:
      // console.log(action.data)
      state.current = action.data
      state.isDirty = false
      state.childStateChanged = false
      break
    case actions.SET_STATE_LOADING:
      state.isLoading = true
      break
    case actions.SET_STATE_LOADED:
      state.isLoading = false
      state.isLoaded = true
      state.childStateChanged = false
      break
    default:
      neverReached()
  }
  return state
})
