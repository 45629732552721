import { responseHandler, responseHelper } from '@asolu/Domain/request'
import { postNotification } from '@asolu/features/Base/kisactions'
import { dispatch } from 'index'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Effects } from './types'
import {  getSectionTypesByMenu, postSection, putSection,  putSectionMenuItems } from './services'
import { AxiosResponse } from 'axios'
import { processedResponseData } from 'lib/actionprocessor'
import { message, Modal } from 'antd'
import { getMenuSections } from '../../../context/services'
import { menuWithSection } from '../../../context/model'
//import { getMenuSections } from '../../../context/services'

export enum Generators {
  PROCESS_CREATESECTION = 'PROCESSCREATESECTION',
  PROCESS_FETCH_SECTIONS = 'PROCESSFETCHSECTIONS',
  PROCESS_FETCH_SECTION = 'PROCESSFETCHSECTION',
  PROCESS_FETCH_SECTIONTYPES = 'PROCESSFETCHSECTIONTYPES',
  PROCESS_UPDATE_MENU_SECTION_ITEMS = 'PROCESSUPDATEMENUSECTIONITEMS',
  FINISHED_CREATESECTION = 'FINISHED_CREATESECTION',
  PROCESS_RESPONSE = 'PROCESSRESPONSE',
  
}

// generators
export function* processResponse({ payload }: ReturnType<typeof actions.processResponse.request>){
  const { status, data} = payload.response
  const name = data.name !== undefined ? data.name : ""
  const handler: responseHandler = responseHelper[status!]
  if (handler !== undefined) {
    const { stateChange, status: processedStatus, messageHeader, messagePrefix } = handler;
    switch (processedStatus) {
      case 'Success': {      
        postNotification(messageHeader, name >"" ? messagePrefix + name : "", 'topLeft', 'Success')
        const success = { data, stateChange } as processedResponseData
        yield put({ type: 'PROCESSING_SUCCESS', success })
        return success
      }
      case 'Fail': {
        postNotification(messageHeader, messagePrefix + name, 'topLeft', 'Fail')
        const fail = { data, stateChange } as processedResponseData
        yield put({ type: 'PROCESSING_FAILURE', payload: fail })
        return fail
      }
    }
  }
  return {data:undefined, stateChange:"FAIL"} as processedResponseData
}

export function* PROCESSDELETESECTION(action: ReturnType<typeof actions.deleteSection.request>) {
  const localModal = Modal.info({
    title: 'Please wait deactivating section type',
    centered: true,
    keyboard: false,
    autoFocusButton: null,
    okButtonProps: { disabled: true, style: { display: 'none' } },
  })
  const {record} = action.payload   
  //
  const response:AxiosResponse<any> = yield call(putSection, record)
  localModal.destroy()
  if(response)
  {
  const ret: processedResponseData = yield dispatch(actions.processResponse.request({response:response,options:{}})) 
     return ret   
  }
  const ret: processedResponseData = { data: undefined, stateChange: "FAIL" }
  return ret
}

export function* PROCESSUPDATESECTION(action: ReturnType<typeof actions.updateSection.request>) {
  const {record} = action.payload
  const localModal = Modal.info({
    title: 'Please wait processing data save',
    centered: true,
    keyboard: false,
    autoFocusButton: null,
    okButtonProps: { disabled: true, style: { display: 'none' } },
  })

  const response:AxiosResponse<any> = yield call(putSection, record)
  localModal.destroy()
  if(response)
  {
  const ret: processedResponseData = yield dispatch(actions.processResponse.request({response:response,options:null}))
  return ret
  }
  const ret: processedResponseData = { data: undefined, stateChange: "FAIL" }
  return ret
}

export function* processCreateSection(action: ReturnType<typeof actions.createSection.request>) {
  const { section, menuId } = action.payload
  const response:AxiosResponse = yield call(postSection, {
    ...section,
    isActive: true,
    Description: section.name,
    primaryMenuId: menuId
  })
  if (response) {
   const processedResponse:processedResponseData = yield dispatch(actions.processResponse.request({response:response,options:null})) 
   if(processedResponse)
   return response;  
  }
 return { data: undefined, stateChange: "FAIL" }
}

export function* PROCESSDELETEMENUSECTION(
  payload: ReturnType<typeof actions.deleteMenuSection.request>,
) {
  const { payload: data } = payload
  message.info("PLease select save once all changes have been made to complete the process")    
  yield data
  const ret: processedResponseData = { data:data, stateChange: "DELETED" }
  return ret
}

export function* PROCESSUPDATEMENUSECIONITEMS(
  action: ReturnType<typeof actions.updateMenuSectionItems.request>,
) {
  const { id, data } = action.payload
  const response:AxiosResponse<any> = yield call(putSectionMenuItems, { id, data })
  if (response) {
      return (yield dispatch( actions.processResponse.request({response:response,options:null}) )) as processedResponseData  
  }
  return { data: undefined, stateChange: "FAIL" } as processedResponseData  
}

export function* PROCESSPUTMENUSECTION(action: ReturnType<typeof actions.updateMenuSection.request>) {
  const {data } = yield action.payload
  return data
  // ( yield call(putMenuSection, id, data))as AxiosResponse
}

export function* PROCESSECTIONSTATEUPDATE(
  payload: ReturnType<typeof actions.updateSectionState.request>,
) {
  yield actions.updateSectionState.request(payload)
}

export function* PROCESSFETCHSECTIONS(payload: any) {
  const {data} = payload
  const {parentId} = data
  const response:AxiosResponse = yield call( getMenuSections, parentId )
  
  const cleansedData = (response.data as menuWithSection[]).filter(item => item.section !=null) 
  const sectionData = cleansedData.map(item =>{
    return item.section       
  })

  yield put({
    type: Effects.AVAILABLE_SECTIONS_LOADED,
    payload: response,
    data: sectionData,
  })
}

export function* PROCESSFETCHSECTIONTYPES(typesPayLoad:any){
   const {data} = typesPayLoad
  const {parentId} = data
  const results:AxiosResponse = yield call(getSectionTypesByMenu, parentId)
  const payload = results.data
  yield put({
    type: Effects.AVAILABLE_SECTIONTYPES_LOADED,
    payload,
  })
}


