
import { all, takeEvery } from 'redux-saga/effects'
import {
  FETCHMENURECEIVED,
  FETCHMENUSTARTED,
  MENUCREATED,
  MENUCREATEDRESPONSEPROCESSED,
  MENUDEACTIVATED,
  MENUEDITED,
  MENUINITIALSTATERESET,
  MENUSAVED,
  MENUSECTIONSDIRTYDATACHANGED,
  MENUTENANTLOCATIONSET,
  PROCESSATTACHSECTION,
  PROCESSATTACHSECTIONS,
  PROCESSFETCHSECTIONS,
  RESPONSEPROCESSED,
  SECTIONDATACHANGED,
   SECTIONSLOADED,
} from './generators'
import { Actions } from './types'
import * as actions from './triggeraction'
import { takeEveryPromiseAction } from 'lib/reduxSagaPromise/effects'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeEveryPromiseAction(actions.attachSectionsToMenu, PROCESSATTACHSECTIONS),
    takeEveryPromiseAction(actions.attachSectionToMenu, PROCESSATTACHSECTION),
    takeEveryPromiseAction(actions.fetchMenuSections, PROCESSFETCHSECTIONS),
    takeEvery(Actions.MENU_INITIALSTATE_RESET, MENUINITIALSTATERESET),
    takeEvery(Actions.MENU_TENANTLOCATION_SET, MENUTENANTLOCATIONSET),
    takeEvery(Actions.MENU_CREATED, MENUCREATED),
    takeEvery(Actions.MENU_CREATED_RESPONSE_PROCESSED, MENUCREATEDRESPONSEPROCESSED ),
    takeEvery(Actions.MENU_SAVED, MENUSAVED),
    takeEvery(Actions.MENU_EDITED, MENUEDITED),  
    takeEvery(Actions.SECTIONS_LOADED, SECTIONSLOADED),
    takeEvery(Actions.FETCH_MENU_STARTED, FETCHMENUSTARTED),
    takeEvery(Actions.FETCH_MENU_RECEIVED, FETCHMENURECEIVED),
    takeEvery(Actions.RESPONSE_PROCESSED, RESPONSEPROCESSED),
    takeEvery(Actions.MENU_DEACTIVATED, MENUDEACTIVATED),
    takeEvery(Actions.SECTION_DATA_CHANGED, SECTIONDATACHANGED),
    takeEvery(Actions.MENUSECTIONS_DIRTY_DATA_CHANGED, MENUSECTIONSDIRTYDATACHANGED)  
  ])
}
