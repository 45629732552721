import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth, app },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              <p>
                <strong>
                  {' '}
                  {app.appName} - {app.appHeroMin}{' '}
                </strong>
              </p>
              <p>&copy; 2023 {app.appName} </p>
            </div>
            <div className="col-md-4">
              <div className={style.logo}>
                <img src="resources/images/air-logo.png" alt="{app.appname}" />
                <div className={style.logoName}>{app.appName}</div>
                <div className={style.logoDescr}>{app.appHeroMin}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
