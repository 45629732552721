import { notification } from 'antd'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
const url = process.env.REACT_APP_API_URI

const codeMessage = {
  200: 'The server successfully returned the requested data. ',
  201: 'New or modified data was successful. ',
  202: 'A request has entered the background queue (asynchronous task). ',
  204: 'Delete data successfully. ',
  400: 'There was an error in the request sent, and the server did not create or modify data. ',
  401: 'The user does not have permission (token, username, password is wrong). ',
  403: 'The user is authorized, but access is prohibited. ',
  404: 'No Match Found ',
  406: 'The requested format is not available. ',
  410: 'The requested resource is permanently deleted and will no longer be obtained. ',
  422: 'When creating an object, a validation error occurred. ',
  500: 'An error occurred on the server, please check the server. ',
  502: 'Gateway error. ',
  503: 'Service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway timed out. ',
}

export interface responseHandler {
  stateChange: 'CREATE' | 'UPDATE' | 'DELETE' | 'FETCHED' | 'INITIAL' | 'FAIL'
  data: any
  status: 'Success' | 'Fail'
  messageHeader: string
  messagePrefix: string
}

export const responseHelper = {
  500: {
    stateChange: 'DELETE',
    status: 'Fail',
    messageHeader: 'Error',
    messagePrefix: `Error , please make sure child records don't exist. For example if deleting a menu, sections and items will need to be removed first.`,
  },
  412: {
    stateChange: 'UPDATE',
    status: 'Fail',
    messageHeader: 'Error saving data',
    messagePrefix: 'Error saving data please contact administrator.',
  },
  200: {
    stateChange: 'FETCHED',
    status: 'Success',
    messageHeader: 'Record found',
    messagePrefix: 'The server successfully returned the requested data.',
  },
  201: {
    stateChange: 'CREATE',
    status: 'Success',
    messageHeader: 'Database Successfully Processed!',
    messagePrefix: 'Record:',
  },
  202: {
    stateChange: 'UPDATE',
    status: 'Success',
    messageHeader: 'Database Successfully Processed!',
    messagePrefix: 'Record:',
  },
  204: {
    stateChange: 'DELETE',
    status: 'Success',
    messageHeader: 'Record Successfully Deleted!',
    messagePrefix: '',
  },
}

export interface gatewayResponse {
  data: any | [] | object
  statusText: string
  status: number
}

// const authToken =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUZXN0RiBUZXN0RiIsImVtYWlsIjoibXlAZW1haWwuY29tIiwiYmlydGhkYXRlIjoiMDAwMS0wMS0wMSIsImp0aSI6ImVlZGFmZWRhLWEzYTEtNGQxMi1iZTIwLWQ5NWYyZjk3OTFjMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBZG1pbmlzdHJhdG9yIiwiQWRtaW5pc3RyYXRvciJdLCJleHAiOjE1ODYyODU2NjUsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NDQzNDIvIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0NDM0Mi8ifQ.4s_C70HaTxMvCOgNYtQatWgoEBqfxmWcdK_KcshUGHc'
// https://github.com/axios/axios
// axios.interceptors.request.use(
//   config => {
//     const token =
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUZXN0RiBUZXN0RiIsImVtYWlsIjoibXlAZW1haWwuY29tIiwiYmlydGhkYXRlIjoiMDAwMS0wMS0wMSIsImp0aSI6ImVlZGFmZWRhLWEzYTEtNGQxMi1iZTIwLWQ5NWYyZjk3OTFjMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBZG1pbmlzdHJhdG9yIiwiQWRtaW5pc3RyYXRvciJdLCJleHAiOjE1ODYyODU2NjUsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NDQzNDIvIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0NDM0Mi8ifQ.4s_C70HaTxMvCOgNYtQatWgoEBqfxmWcdK_KcshUGHc'
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`
//       // console.log(token)
//     }
//     // config.headers['Content-Type'] = 'application/json';
//     return config
//   },
//   error => {
//     Promise.reject(error)
//   },
// )

// Global Axios Instance
const ApiGatewayInstance = axios.create({
  baseURL: url,
  headers: {
    //   authorization: { authToken },
  },
})

ApiGatewayInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
ApiGatewayInstance.defaults.headers.post.Accept = 'application/json'
ApiGatewayInstance.defaults.headers.post['Content-Type'] = 'application/json'
ApiGatewayInstance.defaults.headers.put.Accept = 'application/json'
ApiGatewayInstance.defaults.headers.put['Content-Type'] = 'application/json'


// Global Response/Request middleware
ApiGatewayInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    const status = response.status as number
    if (status > 400 && status !== 404) {
      return response
    }
    return response
  },
  (error: { response: any }) => {
    return error.response
  },
)

ApiGatewayInstance.interceptors.request.use(
  (request: any) => {
    // console.log(request)
    // let origURL = request.url!
    // {menuoptionsproxy=
    // request.url = `/{proxy}/${origURL}`
    //  console.log(request)
    return request
  },
  (error: any) => {
    return error
  },
)

export const ApiGateway = ApiGatewayInstance

/**
 * Exception handler
 */
export const errorHandler = (error: { response: Response }): Response => {
  const { response } = error

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText
    const { status, url } = response

    if (status !== 404) {
      notification.error({
        message: `Request error ${status}: ${url}`,
        description: errorText,
      })
    }
  } else if (!response) {
    notification.error({
      description: 'Your network is abnormal and cannot connect to the server',
      message: 'Network exception',
    })
  }
  return response
}


export const useAxiosGateway = (base=url, Auth?:string)=> {
// Global Axios Instance
const ApiGatewayInstance = axios.create({
  baseURL: url,
  headers: {
    //   authorization: { authToken },
  },
})

// set header defaults
ApiGatewayInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
ApiGatewayInstance.defaults.headers.post.Accept = 'application/json'
ApiGatewayInstance.defaults.headers.post['Content-Type'] = 'application/json'
ApiGatewayInstance.defaults.headers.put.Accept = 'application/json'
ApiGatewayInstance.defaults.headers.put['Content-Type'] = 'application/json'

// added interceptors 
// Global Response/Request middleware
ApiGatewayInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    const status = response.status as number
    if (status > 400 && status !== 404) {
      return response
    }
    return response
  },
  (error: { response: any }) => {
    return error.response
  },
)

ApiGatewayInstance.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    // console.log(request)
    // let origURL = request.url!
    // {menuoptionsproxy=
    // request.url = `/{proxy}/${origURL}`
    //  console.log(request)
    return request
  },
  (error: any) => {
    return error
  },
)


return [ApiGatewayInstance]
}

