import produce from 'immer'
import { sectionsState, sectionsInitialState } from './model'
import { Effects } from './types'

export const restaurantMenuSectionReducer = produce(
  (state: sectionsState = sectionsInitialState, action: any) => {
    const { type } = action
    switch (type) {      
      case Effects.CREATE_SECTION_SUCCESS: {
        // Need to see if this is used by add section row
        const { payload } = action
        const { data, stateChange } = payload
        state.stateChange = stateChange
        state.availableSectionTypes.push(data)
        state.isDirty = true
        return state
      }
      case Effects.DELETE_SECTION_SUCCESS: {  
        const { payload } = action
        const { data } = payload
        state.stateChange = 'DELETED'
        const indexIdToUpdate = state.availableSectionTypes.findIndex(section =>
          section.id === data.id)
        state.availableSectionTypes.splice(indexIdToUpdate,1)
        return state
      }
      case Effects.UPDATE_SECTION_SUCCESS: {
        const { payload } = action
        const { data } = payload
        const indexIdToUpdate = state.availableSectionTypes.findIndex(section =>
          section.id === data.id)
        state.stateChange = 'UPDATED'
        state.availableSectionTypes[indexIdToUpdate] = data
        return state
      }
      case Effects.SECTION_SAVED:
        state.current = action.data
        state.stateChange = 'UPDATED'
        break
      case Effects.SECTION_FETCHED:
        state.current = action.data
        state.stateChange = 'FETCHED'
        break
      case Effects.SECTION_LOADED:
        state.current = action.data
        state.stateChange = 'LOADED'
        break
      case Effects.AVAILABLE_SECTIONS_LOADED:
        state.availableSections = action.data
        state.stateChange = 'LOADED'
        break
      case Effects.AVAILABLE_SECTIONTYPES_LOADED:
        state.availableSectionTypes = action.payload
        state.stateChange = 'LOADED'
        break
      default:
      // neverReached()
    }
    return state
  },
)
