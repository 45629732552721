/* eslint-disable import/no-cycle */
import { responseHandler, responseHelper } from '@asolu/Domain/request'
import notification, { NotificationPlacement } from 'antd/lib/notification'
// import { AxiosResponse } from "axios"
import { dispatch } from 'index'
import { put } from 'redux-saga/effects'

// eslint-disable-next-line @typescript-eslint/naming-convention
export type baseState = {
  isDirty: boolean
  dataWasTouched: boolean
  stateChange: 'CREATED' | 'UPDATED' | 'DELETED' | 'FETCHED' | 'INITIALIZED' | 'LOADED' | 'FAIL'
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum actionType {
  Get,
  Add,
  Edit,
  Delete,
}

export interface IActionProps {
  _actionType: actionType
  key: number
  row?: any
  data?: any
  listofopts1?: number[]
  listofopts2?: number[]
  listofopts3?: number[]
  filterOpts1?: string | string[] | undefined
  filterOpts2?: string | string[] | undefined
  filterOpts3?: number | 0
}

export interface IActionReturn {
  id?: number
  header?: string
  actionName?: string
  data?: any
}

export class Actions {}

// Request Helper Class

export const asyncActionTypeCreator = (prefix: any) => {
  const asyncTypeAction = ['_INDEX', '_REQUEST', '_SUCCESS', '_FAILURE']

  const actionNameIndex = asyncTypeAction[0]
  const actionNameRequest = asyncTypeAction[1]
  const actionNameSuccess = asyncTypeAction[2]
  const actionNameFailure = asyncTypeAction[3]

  return {
    INDEX: prefix + actionNameIndex,
    REQUEST: prefix + actionNameRequest,
    SUCCESS: prefix + actionNameSuccess,
    FAILURE: prefix + actionNameFailure,
  }
}

// export function asyncActionCreator(actions: { INDEX: any; REQUEST: any; SUCCESS: any; FAILURE: any }) {
//   let actionCreator = createAction(actions.INDEX);
//   actionCreator.request = createAction(actions.REQUEST);
//   actionCreator.success = createAction(actions.SUCCESS);
//   actionCreator.failure = createAction(actions.FAILURE);
//   return actionCreator
// }

export const Dispatcher = async (generator: any, payload: any) => {
  await dispatch({
    type: generator,
    data: payload,
  })
}

export const DispatcherAsync = (generator: any, payload: any) => {
  dispatch(generator(payload))
    .then(() => alert('Success!'))
    .catch((err: any) => console.error(err))
}

export function* Putter(effect: any, payload: any) {
  yield put({
    type: effect,
    payload,
  })
}

// export const ResponseDispatcher = async(generator:any,response:any,isDirty:boolean,stateChange:any) =>
// {
//     await dispatch({
//         type:generator,
//         response: response,
//         isDirty:isDirty,
//         stateChange:stateChange
//     }
//     )
// }

export const postNotification = (
  message: string,
  description: string,
  placement: NotificationPlacement,
  type: 'Success' | 'Fail',
) => {
  switch (type) {
    case 'Success':
      notification.success({
        message,
        description,
        placement,
      })
      break
    case 'Fail':
      notification.error({
        message,
        description,
        placement,
      })
      break

    default:
      break
  }
}

export function* processResponse(response: any, successGenerator: any) {
  // console.log(response)
  const { status, data } = response
  const name = data.name !== undefined ? data.name : data
  const handler: responseHandler = responseHelper[status!]
  switch (handler.status) {
    case 'Success':
      if (handler.stateChange !== 'DELETE') {
        yield* successGenerator(response, false, {
          response,
          stateChange: handler.stateChange,
        })
      }
      postNotification(handler.messageHeader, handler.messagePrefix + name, 'topLeft', 'Success')
      return true
    case 'Fail':
      postNotification(handler.messageHeader, handler.messagePrefix + name, 'topLeft', 'Fail')
      return false
    default:
      return false
  }
}
