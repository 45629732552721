import { Dispatcher } from '@asolu/features/Base/kisactions'
import { AxiosResponse } from 'axios'
import { dispatch } from 'index'
import { processedResponseData } from 'lib/actionprocessor'
import { createPromiseAction } from 'lib/reduxSagaPromise'
import { section } from '../../../context/model'
import { Generators } from './generators'
import { Actions , request } from './types'

export {Actions}

export const createSection = createPromiseAction(
  'CREATE_SECTION_REQUEST',
  'CREATE_SECTION_SUCCESS',
  'CREATE_SECTION_FAILURE',
)<request, unknown, unknown>()

export const updateSection = createPromiseAction(
  'UPDATE_SECTION_REQUEST',
  'UPDATE_SECTION_SUCCESS',
  'UPDATE_SECTION_FAILURE',
)<{  record: section }, unknown, unknown>()

export const deleteSection = createPromiseAction(
  'DELETE_SECTION_REQUEST',
  'DELETE_SECTION_SUCCESS',
  'DELETE_SECTION_FAILURE',
)<{ record: {} }, unknown, unknown>()

export const updateSectionState = createPromiseAction(
  'UPDATE_SECTION_STATE_REQUEST',
  'UPDATE_SECTION_STATE_SUCCESS',
  'UPDATE_SECTION_STATE_FAILURE',
)<unknown, unknown, unknown>()

export const deleteMenuSection = createPromiseAction(
  'DELETE_MENU_SECTION_REQUEST',
  'DELETE_MENU_SECTION_SUCCESS',
  'DELETE_MENU_SECTION_FAILURE',
)<request, processedResponseData, processedResponseData>()

// update menuSection
export const updateMenuSection = createPromiseAction(
  'UPDATE_MENU_SECTION_REQUEST',
  'UPDATE_MENU_SECTION_SUCCESS',
  'UPDATE_MENU_SECTION_FAILURE',
)<{ id: number; data: any }, unknown, unknown>()
// update menuSectionItems
export const updateMenuSectionItems = createPromiseAction(
  'UPDATE_MENU_SECTION_ITEMS_REQUEST',
  'UPDATE_MENU_SECTION_ITEMS_SUCCESS',
  'UPDATE_MENU_SECTION_ITEMS_FAILURE',
)<{ id: number; data: any }, unknown, unknown>()

export const processResponse = createPromiseAction(
  'PROCESS_RESPONSE_REQUEST',
  'PROCESS_RESPONSE_SUCCESS',
  'PROCESS_RESPONSE_FAILURE',
)<{ response: AxiosResponse, options?: any }, unknown, unknown>()

// export const TriggerAction = async<T,P,R>(  action: T, payload:P    ) =>
export const TriggerAction = async (action: Actions, payload: any) => {
  let ret
  switch (action) {
    case Actions.DELETE_MENU_SECTION:
      return await dispatch(deleteMenuSection.request(payload))
    case Actions.UPDATE_MENU_SECTION:
      return await dispatch(updateMenuSection.request(payload))
    case Actions.UPDATE_MENU_SECTION_ITEMS:
      return await dispatch(updateMenuSectionItems.request(payload))
    case Actions.CREATE_SECTION_TYPE:
      return await dispatch(createSection.request(payload as request))
    case Actions.UPDATE_SECTION:
      return await dispatch(updateSection.request(payload))
    case Actions.DELETE_SECTION:
      return await dispatch(deleteSection.request(payload))
    case Actions.UPDATE_SECTION_STATE:
      return await dispatch(updateSectionState.request(payload))
    case Actions.FETCH_SECTIONS:
      return await Dispatcher(Generators.PROCESS_FETCH_SECTIONS, payload)
    case Actions.FETCH_SECTIONTYPES:
      return await Dispatcher(Generators.PROCESS_FETCH_SECTIONTYPES, payload)
    case Actions.INFORM_CREATESECTIONCOMPLETE:
      return Dispatcher(Generators.FINISHED_CREATESECTION, payload)
    case Actions.FETCH_SECTION:
      return Dispatcher(Generators.PROCESS_FETCH_SECTION, payload)
    default:
      break
  }
  return ret
}

