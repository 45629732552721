import { section } from '@asolu/features/RestaurantMenu/context/model'

export type request = {
  indexId: number
  section: section
  menuId?: number
}

// Action Result Effect

export enum Actions {
  UPDATE_SECTION = 'UPDATESECTION',
  DELETE_SECTION = 'DELETESECTION',
  UPDATE_SECTION_STATE = 'UPDATESECTIONSTATE',
  UPDATE_MENU_SECTION = 'UPDATEMENUSECTION',
  DELETE_MENU_SECTION = 'DELETEMENUSECTION',
  UPDATE_MENU_SECTION_ITEMS = 'UPDATEMENUSECTIONITEMS',
  CREATE_SECTION_TYPE = 'CREATESECTIONTYPE',
  CREATE_SECTION = 'CREATESECTION',
  FETCH_SECTIONS = 'FETCH_SECTIONS',
  FETCH_SECTION = 'FETCH_SECTION',
  FETCH_SECTIONTYPES = 'FETCH_SECTIONTYPES',
  RESPONSE_RETURNED = 'RESPONSERETURNED',
  INFORM_CREATESECTIONCOMPLETE = 'INFORMCREATESECTIONCOMPLETE',
  ATTACH_SECTIONS_TO_MENU = "ATTACH_SECTIONS_TO_MENU",
  ATTACH_SECTION_TO_MENU = "ATTACH_SECTION_TO_MENU"
}

export enum Effects {
  UPDATE_MENU_SECTION_SUCCESS = 'UPDATE_MENU_SECTION_SUCCESS',
  DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS',
  SECTIONS_INITIALSTATE_RESET = 'SECTIONINITIALSTATERESET',
  SECTIONS_INITIALSTATE_SET = 'SECTIONINTIALSTATESET',
  FETCH_SECTIONS_STARTED = 'FETCHSECTIONSSTARTED',
  FETCH_SECTIONS_RECEIVED = 'FETCHSECTIONSRECEIVED',
  FETCH_SECTIONS_FAILED = 'FETCHSECTIONSFAILED ',
  AVAILABLE_SECTIONS_LOADED = 'AVAILABLE_SECTIONS_LOADED',
  AVAILABLE_SECTIONTYPES_LOADED = 'AVAILABLE_SECTIONTYPES_LOADED',
  SECTION_DEACTIVATED = 'SECTIONDEACTIVATED',
  CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS',
  UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS',
  SECTION_CREATED_COMPLETE = 'SECTIONCREATEDCOMPLETED',
  SECTION_SAVED = 'SECTIONSAVED',
  SECTION_EDITED = 'SECTIONEDITED',
  SECTION_FETCHED = 'SECTIONFETCHED',
  SECTION_LOADED = 'SECTIONLOADED',
  RESPONSE_RETURNED = 'RESPONSERETURNED',
  SECTION_ORDER_CHANGED = 'SECTIONORDERCHANGED',
}
