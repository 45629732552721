import { all, takeEvery, put, call } from 'redux-saga/effects'
import { dispatch } from 'index'
import { notification } from 'antd'
import { history, store as reduxStore } from 'index'
import { login, currentAccount, logout ,setAgreement, setTenant} from 'services/user'
import { store as sessionStore } from 'store2'
import store from 'store'
import actions from './actions'
import { getTenant } from '@asolu/features/RestaurantMenu/components/dashboard/context/service'

export function* SET_SESSION({ payload: { setting, value } }) {
  yield sessionStore.session(`app.settings.${setting}`, value)
}

export function* SET_AGREEMENT({ payload }) {
  const { privacyAgreed, eulaAgreed } = payload
  const overallAgreed = eulaAgreed
  if (overallAgreed === true) { sessionStorage.setItem('agreed', overallAgreed) }
  else {
    sessionStorage.removeItem('agreed')
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      privacyAgreed: overallAgreed,
      eulaAgreed: overallAgreed
    },
  })
}

export function* LOGIN_GUEST({ payload }) {
  const { tenantId, privacyAgreed, eulaAgreed } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: 0,
      name: 'guest',
      email: '',
      avatar: '',
      role: 'guest',
      authorized: true,
      tenantId: tenantId,
      privacyAgreed: privacyAgreed,
      eulaAgreed: eulaAgreed
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })
  if (success) {
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
}

const getTenantName = (id) => {
  return getTenant(id).then((response) => {
    const { status, data } = response
    const tenantName = data !== undefined ? data.name : ""
    return tenantName
  }
  )
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { uid: id, email, photoURL: avatar } = response
    let tenantId = 0;
    switch (email) {
      case "demo@gattitown.com": 
        tenantId = 11
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      case "demo@loginn.com":
        tenantId = 10
            dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      case "saucedadmin@alsolutions.com":      
      tenantId = 9 
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      case "demo@alsolutions.com":
        tenantId = 7
              dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      case "rhadmin@alsolutions.com":
        tenantId =5 
               dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      case "entadmin@alsolutions.com":      
      tenantId =1 
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
      default:
        tenantId = 0
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'tenantId',
            value: tenantId,
          },
        })
        break;
    }
    console.log(tenantId)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: 'Administrator',
        email,
        avatar,
        role: 'admin',
        authorized: true,
        tenantId: tenantId,
        tenantName: yield getTenantName(tenantId)
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield call(setAgreement)
  yield call(setTenant, 0 )
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.SET_SESSION, SET_SESSION),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SET_AGREEMENT, SET_AGREEMENT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
