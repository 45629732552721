import { ApiGateway, gatewayResponse } from 'pages/AllergenSolu/Domain/request'

export const getTenant = async (id: number) => {
  if (id !== undefined) {
    const response: gatewayResponse = await ApiGateway(`/api/TenantAsync/${id}`)
    return response
  }
  return undefined
}

export const getMenusByLocation = async (id: number): Promise<gatewayResponse | undefined> => {
  if (id !== undefined) {
    const response: gatewayResponse = await ApiGateway(`/api/MenuAsync/GetByLocation/${id}`)
    return response
  }
  return undefined
}
